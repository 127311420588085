import React, { useState ,useEffect} from "react";
import { Dropdown, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Avatar from "../assets/img/default.jpg";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
const Header = ({ title }) => {

    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {
        const userData = localStorage.getItem("userDetail");
        if (userData) {
          var data = JSON.parse(userData);

          setSelectedFile(`${PIC.PIC_URL}${data.pic}`);
          
        }
    
      }, []);

    return (
        <header className="header">
            <Row>
                <Col xs={6} className="align-self-center">
                    <div className="mainbreadcrumb d-none d-md-block">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
                            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                        </Breadcrumb>
                        {/* <h1>{title}</h1> */}
                    </div>
                </Col>
                <Col xs={6} className="text-end">
                    <div className='headerright' style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Link underline="none" className="mx-3 linksheader" to="/NotificationScreen">
                            <Icon icon="solar:bell-outline" />
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="p-0">
                                <img alt="" src={selectedFile?selectedFile:Avatar} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/ProfileSettings">Profile Settings</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </header>
    )
}

export default Header;