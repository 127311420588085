import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import * as API from "../../services/env";
import * as PIC from "../../services/env";
import { Oval } from "react-loader-spinner";
export default function Contactqueries() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [idShow, setidShow] = useState(false);

  const [loader, setloader] = useState(false);
  const handleShow = (id) => {
    setShow(true); 
    setidShow(id)
   
  };
  const columns = [
    {
      name: 'Organization Name',
      minWidth: "150px",
      selector: row => row.name,
      sortable: true,
      cell: (row) => row.name    
    },
    {
      name: 'Email',
      minWidth: "220px",
      selector: row => row.email,
      sortable: true,
      cell: (row) => row.email
    },
    {
      name: 'Phone Number',
      minWidth: "180px",
      selector: row => row.phoneNumber,
      sortable: true,
      cell: (row) => row.phoneNumber
    },
    {
      name: 'Query',
      minWidth: "350px",
      selector: row => row.query,
      sortable: true,
      cell: (row) => row.query
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btndelete" onClick={()=>handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data, setData] = useState([
    // { 
    //   id: 1, 
    //   name: 'Maria James', 
    //   email: 'mariajames@gmail.com',  
    //   phone: '+1 12345 67890',  
    //   Query: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
    // },
    // { 
    //   id: 2, 
    //   name: 'Johnson Charles', 
    //   email: 'johson@gmail.com',  
    //   phone: '+1 12345 67890',  
    //   Query: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
    // },
    // { 
    //   id: 3, 
    //   name: 'David Smith', 
    //   email: 'david@gmail.com',  
    //   phone: '+1 12345 67890',  
    //   Query: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
    // },
    // { 
    //   id: 4, 
    //   name: 'Steve Jones', 
    //   email: 'steve@gmail.com',  
    //   phone: '+1 12345 67890',  
    //   Query: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
    // },
    // { 
    //   id: 5, 
    //   name: 'James Vince', 
    //   email: 'jamese@gmail.com',  
    //   phone: '+1 12345 67890',  
    //   Query: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
    // },
  ]);

  // Filter data based on search text
  const filteredData = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) !== -1
  );


  const onFtechSupportDetail = async () => {
    setloader(true);
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
    await fetch(`${API.BASE_URL}AdminGetSupport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        // token: data.authentication_token,
  
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloader(false);
        if (res.status == true) {
          setData(res.supportTable)
       
        } else if (res.msg) {
          // toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  const onSubmit = async () => {
  
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);


    }
     await fetch(`${API.BASE_URL}deleteSupport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        // token: data.authentication_token,
        deleteID: idShow,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setShow(false)
          window.location.reload()
        } 
      })
      .catch((error) => {});
  };
  useEffect(() => {
    // onSubmit();
    onFtechSupportDetail()
  }, []);

  return (



    <React.Fragment>


{loader == true ? (
        <Oval
          height="30"
          width="30"
          radius="30"
          color="#f2f5f3"
          ariaLabel="loading"
          wrapperStyle={{ marginLeft: 500, marginTop: 150 }}
          wrapperClass
        />
      ) : (

        <>
        
        
        <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-4">
              <h5>Contact Queries</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-secondary px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={onSubmit}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
        </>
      )}


     
    </React.Fragment>
  );
}
