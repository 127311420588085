import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Avatar from "../assets/img/default.jpg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import { Oval } from "react-loader-spinner";

export default function Adduser() {
  const [name, setName] = useState("");
  const [nameErr, setnameErr] = useState("");

  const [Type, setType] = useState("");
  const [TypeErr, setTypeErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setemailErr] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErr, setphoneErr] = useState("");

  const [TAXID, setTAXID] = useState("");
  const [TAXIDErr, setTAXIDErr] = useState("");

  const [Address, setAddress] = useState("");
  const [AddressErr, setAddressErr] = useState("");

  const [Bio, setBio] = useState("");

  const [BioErr, setBioErr] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const [loader, setloader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");

  const isValidEmail = (email) => {
    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (!email.trim()) {
      // Check if email is empty or only contains whitespace
      errorCount++;
      let msg = "Please enter your email.";
      setemailErr(msg);
    } else if (!isValidEmail(email)) {
      errorCount++;
      let msg = "Please enter a valid email address.";
      setemailErr(msg);
    } else {
      setemailErr(null);
    }

    if (SERVICE.hasError(name)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your organization name.";
      setnameErr(msg);
    } else {
      setnameErr(null);
    }

    if (SERVICE.hasError(Type)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your organization type.";
      setTypeErr(msg);
    } else {
      setTypeErr(null);
    }

    if (!phone || !/^\d+$/.test(phone)) {
      errorCount++;
      setloader(false);
      let msg = !phone
        ? "Please enter a phone number."
        : "Please enter a valid phone number.";
      setphoneErr(msg);
    } else {
      setphoneErr(null);
    }

    if (SERVICE.hasError(TAXID)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your taxid.";
      setTAXIDErr(msg);
    } else {
      setTAXIDErr(null);
    }

    if (SERVICE.hasError(Address)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your address.";
      setAddressErr(msg);
    } else {
      setAddressErr(null);
    }

    if (SERVICE.hasError(Bio)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your bio.";
      setBioErr(msg);
    } else {
      setBioErr(null);
    }
    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onChange = async (evt) => {
    const pic = evt.target.files[0];
    if (evt.target.files[0] != undefined) {
      const target = evt.target.files[0];
      setSelectedFile(target);
      setPreview(URL.createObjectURL(evt.target.files[0]));
      let data = new FormData();
      const image = evt.target.files[0];
      data.append("uploadFile", image);
    }
  };

  const onSubmit = async () => {
    setloader(true);
    setSubmit(true);
    if (
      email == "" ||
      name == "" ||
      Type == "" ||
      TAXID == "" ||
      phone == "" ||
      Address == "" ||
      Bio == ""
    ) {
      setloader(false);
    }
    onProfileValidation();
    if (errorCount > 0) return;
    let data = new FormData();

    data.append("uploadFile", selectedFile);
    data.append("organisationName", name);
    data.append("Organization_Type", Type);
    data.append("email", email);
    data.append("Phone_Number", phone);
    data.append("TaxId", TAXID);
    data.append("Address", Address);
    data.append("Brief_Bio", Bio);
    // data.append("id", userid);

    var requestOptions = {
      method: "POST",
      body: data,
    };

    let payload = {
      email: email,
      organisationName: name,
      Organization_Type: Type,
      TaxId: TAXID,
      Phone_Number: phone,
      Address: Address,
      Brief_Bio: Bio,
    };

    const response = await fetch(`${API.BASE_URL}adminAdduser`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);
        if (res.status == true) {
          toast("User added successfully.");
        } else if (res.msg == "email already exist.") {
          toast("Email already exists.");
        } else if (res.msg == "email already exist.") {
          toast("Email already exists.");
        } else if (res.msg == "please enter another organisation name.") {
          toast("please enter another organisation name.");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, name, Type, TAXID, phone, Address, Bio]);

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add User</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Users"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 ">
              <div className="user_info text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={preview ? preview : Avatar}
                    className="rounded-circle img-fluid profilepic"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  type="file"
                  id="uploadprofileimg"
                  className="d-none"
                  onChange={onChange}
                />
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Organization Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Organization Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {nameErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {nameErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Organization Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Organization Type"
                        value={Type}
                        onChange={(e) => setType(e.target.value)}
                      />
                      {TypeErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {TypeErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {emailErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone No."
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />

                      {phoneErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {phoneErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>TAX ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter TAX ID"
                        value={TAXID}
                        onChange={(e) => setTAXID(e.target.value)}
                      />
                      {TAXIDErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {TAXIDErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      />

                      {AddressErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {AddressErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Brief Bio</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Brief Bio"
                        value={Bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                      {BioErr && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {BioErr}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                {loader == true ? (
                  <Oval
                    height="30"
                    width="30"
                    radius="30"
                    color="#f2f5f3"
                    ariaLabel="loading"
                    wrapperStyle={{ marginLeft: 40 }}
                    wrapperClass
                  />
                ) : (
                  <Button
                    className="btn btn-primary px-4 mt-3 min_width140"
                    onClick={(e) => {
                      onSubmit();
                    }}
                  >
                    Save
                  </Button>
                )}
                <ToastContainer />
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
