import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";



// import { Oval } from "react-loader-spinner";

export default function Login() {
  const navigate = useNavigate();

  const [loader, setloader] = useState(false);
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(0);
  const [password, setpassword] = useState("");

  const [passwordError, setpasswordError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };
  const onSubmit = async () => {
    setloader(true);
    setSubmit(true);
    if (email == "" || password == "") {
      setloader(false);
    }
    onProfileValidation();
    if (errorCount > 0) return;

    let payload = {
      email: email,
      password: password,
    };

    await fetch(`${API.BASE_URL}adminSignIn`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);

        if (res.status == true) {
          // await localStorage.clear();

          navigate("/Dashboard");
          await localStorage.setItem("IsLogin", true);
          await localStorage.setItem("IsLogin", true);

          await localStorage.setItem("userDetail", JSON.stringify(res.data));
          await localStorage.setItem("token", JSON.stringify(res.token));

          await localStorage.setItem("email", JSON.stringify(email));
          toast("Welcome To Accel Admin Panel");
        } else if (res.msg == "You have provided wrong password") {
          toast("You have provided wrong password");
        } else if (
          res.msg == "Either your domain, email or password is wrong"
        ) {
          toast("Either your email or password is wrong");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, password]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Welcome to ACCEL</h2>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  onChange={(e) => setemail(e.target.value)} // set the onChange handler
                  value={email}
                />
                {emailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {emailError}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                  value={password}
                />
                {passwordError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {passwordError}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4 form-group forgotpassword text-end">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </Form.Group>

              {/* {loader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#f2f5f3"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 230 }}
                  wrapperClass
                />
              ) : (
                <Button
                  as="input"
                  // type="submit"
                  value="Login"
                  className="btn btn-primary w-100"
                  onClick={(e) => {
                    onSubmit();
                  }}
                />

                
              )} */}

              <Button
                as="input"
                // type="submit"
                value="Login"
                className="btn btn-primary w-100"
                onClick={(e) => {
                  onSubmit();
                }}
              />

              {/* <p className="formfooter text-center mt-3">Don't have an account? <Link to="/SignUp">Sign Up</Link></p> */}
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
          <ToastContainer />
        </Col>
      </Row>
    </React.Fragment>
  );
}
