import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";
export default function Editsubscription() {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");

  const [Description, setDescription] = useState("");

  const [loader, setloader] = useState(false);

  const [priceId, setpriceId] = useState("");

  const updateSubscriptionDetail = async (id) => {
    const userData = localStorage.getItem("userDetail");
    setloader(true);

    await fetch(`${API.BASE_URL}adminUpdateSubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        priceId: priceId,
        prize:price,
         interval:type,
         title:title,
         description:Description


        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloader(false);

        if (res.status == true) {
        toast('Prize Updated Successfully')
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  const fetchSubscriptionDetail = async (id) => {
    const userData = localStorage.getItem("userDetail");
    setloader(true);


    await fetch(`${API.BASE_URL}getPerPaymnetDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        priceId: id,

        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloader(false);

        if (res.status == true) {
          setTitle(res.price.product.name);
          setPrice(`${res.price.unitAmount / 100}`);
          setType(res.price.interval);
          setDescription(res.price.product.description);
          //   setdata(res.userdeatil[0]);
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const urlParts = currentUrl.split("/");

    // Get the last part of the URL, which is the user ID
    const lastPart = urlParts[urlParts.length - 1];
    fetchSubscriptionDetail(lastPart);
    setpriceId(lastPart)
  }, []);


  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit Subscription</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Subscriptions"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={12}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        {type ? (
                          <option value={type}>{type}</option>
                        ) : (
                          <option value="select">Select Type</option>
                        )}

                        <option value="1">Yearly</option>
                        <option value="2">Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Description</Form.Label>
                      <CKEditor
                        // editor={ ClassicEditor }
                        // data="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>"
                        // onReady={ editor => {
                        //     // You can store the "editor" and use when it is needed.
                        //     //console.log( 'Editor is ready to use!', editor );
                        // } }
                        // onChange={ ( event ) => {
                        //     //console.log( event );
                        // } }
                        // onBlur={ ( event, editor ) => {
                        //     //console.log( 'Blur.', editor );
                        // } }
                        // onFocus={ ( event, editor ) => {
                        //     //console.log( 'Focus.', editor );
                        // } }
                        editor={ClassicEditor}
                        data={Description?Description:'please add description'}
                        onChange={handleDescriptionChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button className="btn btn-primary px-4 mt-3 min_width140" onClick={()=>updateSubscriptionDetail()}>
                  Save
                </Button>
          <ToastContainer/>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
