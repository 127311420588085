import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import userlogo1 from "../assets/img/userlogo1.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";

export default function Dashboard() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loader, setloader] = useState(false);
  const [totalCount, settotalCount] = useState("");
  const [userCount, setuserCount] = useState("");
  const [notificationCount, setnotificationCount] = useState("");

  const columns = [
    {
      name: "User Profile",
      selector: (row) => row.pic,
      sortable: true,
      cell: (row) => (
        <>
        {row.pic?<img
            className="table_img rounded-circle img-thumbnail me-2"
            src={`${PIC.PIC_URL}${row.pic}`}
          />:''}
          
          {/* {row.organisationName} */}
        </>
      ),
    },

    {
      name: "Organization Name",
      selector: (row) => row.organisationName?row.organisationName:'N/A',
      sortable: true,
      // cell: (row) => (
      //   <>
      //     <img
      //       className="table_img rounded-circle img-thumbnail me-2"
      //       src={row.imgurl}
      //     />
      //     {row.name}
      //   </>
      // ),
    },
    {
      name: "Organization Type",
      selector: (row) => row.Organization_Type?row.Organization_Type:'N/A',
      sortable: true,
      cell: (row) => row.Organization_Type?row.Organization_Type:'N/A',
    },
    {
      name: "Email",
      selector: (row) => row.email?row.email:'N/A',
      sortable: true,
      cell: (row) => row.email?row.email:'N/A',
    },
    {
      name: "Phone No.",
      selector: (row) => row.Phone_Number?row.Phone_Number:'N/A',
      sortable: true,
      cell: (row) => row.Phone_Number?row.Phone_Number:'N/A' ,
    },

    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview"  to={`/Viewuser/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data, setData] = useState([
    // {
    //   id: 1,
    //   name: "Kinetic Shipping Co.",
    //   imgurl: userlogo1,
    //   email: "kineticshipping@gmail.com",
    //   phone: "+91 8053667426",
    //   Type: "Trading Shipping",
    // },
    // {
    //   id: 2,
    //   name: "Kinetic Shipping Co.",
    //   imgurl: userlogo1,
    //   email: "kineticshipping@gmail.com",
    //   phone: "+91 8053667426",
    //   Type: "Trading Shipping",
    // },
    // {
    //   id: 3,
    //   name: "Kinetic Shipping Co.",
    //   imgurl: userlogo1,
    //   email: "kineticshipping@gmail.com",
    //   phone: "+91 8053667426",
    //   Type: "Trading Shipping",
    // },
    // {
    //   id: 4,
    //   name: "Kinetic Shipping Co.",
    //   imgurl: userlogo1,
    //   email: "kineticshipping@gmail.com",
    //   phone: "+91 8053667426",
    //   Type: "Trading Shipping",
    // },
    // {
    //   id: 5,
    //   name: "Kinetic Shipping Co.",
    //   imgurl: userlogo1,
    //   email: "kineticshipping@gmail.com",
    //   phone: "+91 8053667426",
    //   Type: "Trading Shipping",
    // },
  ]);

  // Filter data based on search text
  if(data.length>0){
    var filteredData = data.length>0&&data.filter(
      (item) =>
        item.userName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.organisationName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  const onSubmit = async () => {
    setloader(true);

    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}adminFetchTotalCount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);

        if (res.status == true) {
          settotalCount(res.totalCount);
          setuserCount(res.userCountDetail);
          setnotificationCount(res.notificationCount);
        }
      })
      .catch((error) => {});
  };


  const onUserFetchDeatil = async () => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
    await fetch(`${API.BASE_URL}AdminFetchUserDeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
  
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setData(res.userdeatil)
       
        } else if (res.msg) {
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    onSubmit();
    onUserFetchDeatil()
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon">
              <Icon icon="tabler:users" />
            </span>
            <div className="stats-cont">
              <h4>Total Users</h4>
              {loader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#056AEC"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 25 }}
                  wrapperClass
                />
              ) : (
                <p>{userCount && userCount}</p>
              )}
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsblue">
              
              <Icon icon="ion:business-outline" />
            </span>
            <div className="stats-cont">
              <h4>Total Screenings</h4>
              {loader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#056AEC"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 25 }}
                  wrapperClass
                />
              ) : (
                <p>{totalCount && totalCount}</p>
              )}
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsgreen">
              <Icon icon="mingcute:notification-line" />
            </span>
            <div className="stats-cont">
              <h4>Notifications</h4>
              {loader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#056AEC"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 25 }}
                  wrapperClass
                />
              ) : (
                <p>{notificationCount && notificationCount}</p>
              )}
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-3">
              <h5>Recent Users</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
