import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import Avatar from "../assets/img/default.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
import { Oval } from "react-loader-spinner";

export default function ProfileSettings() {
  const [name, setName] = useState("");
  const [nameErr, setnameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setemailErr] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErr, setphoneErr] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const [loader, setloader] = useState(false);
  const [preview, setPreview] = useState("");
  const [userId, setuserId] = useState("");
  const [password, setpassword] = useState("");
  const [passworderr, setpassworderr] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [confirmPasserr, setconfirmPasserr] = useState("");
  const [Newpassword, setNewpassword] = useState("");
  const [Newpassworderr, setNewpassworderr] = useState("");
  const [key, setKey] = useState("basicinfo");
  
  const [Submit1, setSubmit1] = useState(false);
  const [errorCount1, seterrCount1] = useState(0);
  const [loader1, setloader1] = useState(false);




  const isValidEmail = (email) => {
    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (!email.trim()) {
      // Check if email is empty or only contains whitespace
      errorCount++;
      let msg = "Please enter your email.";
      setemailErr(msg);
    } else if (!isValidEmail(email)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter a valid email address.";
      setemailErr(msg);
    } else {
      setemailErr(null);
    }

    if (SERVICE.hasError(name)) {
      errorCount++;
      setloader(false);

      let msg = "Please enter your name.";
      setnameErr(msg);
    } else {
      setnameErr(null);
    }

    if (!phone || !/^\d+$/.test(phone)) {
      errorCount++;
      setloader(false);
      let msg = !phone
        ? "Please enter a phone number."
        : "Please enter a valid phone number.";
      setphoneErr(msg);
    } else {
      setphoneErr(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onProfileValidationPassword = async () => {
    let errorCount = 0;

    if (SERVICE.hasError(password)) {
     
      errorCount++;
      let msg = "Please enter your password.";
      setpassworderr(msg);
    } else {
      setpassworderr(null);
    }
    if (SERVICE.hasError(Newpassword)) {

      errorCount++;
      let msg = "Please enter your new password.";
      setNewpassworderr(msg);
    } else {
      setNewpassworderr(null);
    }
    if (SERVICE.hasError(confirmPass)) {
   
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPasserr(msg);
    } else {
      setconfirmPasserr(null);
    }

    if (errorCount > 0) return seterrCount1(errorCount);
    else seterrCount1(0);
  };

  const onChange = async (evt) => {
    const pic = evt.target.files[0];
    if (evt.target.files[0] != undefined) {
      const target = evt.target.files[0];
      setSelectedFile(target);
      setPreview(URL.createObjectURL(evt.target.files[0]));
      let data = new FormData();
      const image = evt.target.files[0];
      data.append("uploadFile", image);
    }
  };

  const onSubmit = async () => {
    setloader(true);
    setSubmit(true);

    if (
      email == ("" || null) ||
      name == ("" || null) ||
      phone == ("" || null)
    ) {
      setloader(false);
    }
    onProfileValidation();
    if (errorCount > 0) return;
    let data = new FormData();

    //console.log("selectedFile", selectedFile);
    data.append("uploadFile", selectedFile);
    data.append("name", name);

    data.append("email", email);
    data.append("Phone_Number", phone);
    data.append("id", userId);

    // data.append("id", userid);

    var requestOptions = {
      method: "POST",
      body: data,
    };

    let payload = {
      email: email,
      organisationName: name,
      Phone_Number: phone,
    };

    const response = await fetch(
      `${API.BASE_URL}updateAdminProfile`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (res) => {
        //console.log("res", res);
        setloader(false);
        if (res.status == true) {
          await localStorage.setItem("userDetail", JSON.stringify(res.data[0]));

          toast("Admin Profile updated successfully.");
          setTimeout(()=>{
            window.location.reload()
                      },[2000])
        } else if (res.msg == "email already exist.") {
          toast("Email already exists.");
        } else if (res.msg == "email already exist.") {
          toast("Email already exists.");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
      setName(data.userName);
      setEmail(data.email);
      setPhone(data.Phone_Number);
      setSelectedFile(`${PIC.PIC_URL}${data.pic}`);
      setPreview(`${PIC.PIC_URL}${data.pic}`);

      setuserId(data._id);

     
    }

    //console.log("data", data);
  }, []);

  const OnResetPassword = async () => {
    setloader1(true)
    setSubmit1(true);
    onProfileValidationPassword();



//console.log('errorCount',errorCount1);

if (
  errorCount1>0
) {
  setloader1(false);
}

    if (errorCount1 > 0) return;
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
  
    let payload = {
      email:data.email,
      newPassword: Newpassword,
      confirmnewpassword: confirmPass,
      oldPassword:password
    };

    //console.log("payload", payload);

    await fetch(`${API.BASE_URL}AdminresetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email:data.email,
        newPassword: Newpassword,
        confirmnewpassword: confirmPass,
        oldPassword:password
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
        setloader1(false)
        if (res.status == true) {
          toast(res.msg);
          
        } else if (res.status == false) {
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, name, phone]);

  useEffect(() => {
    onProfileValidationPassword();
  }, [password, Newpassword, confirmPass]);


  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Profile Settings</h5>
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="basicinfo"
            id="uncontrolled-tab-example"
            className="customtabs mb-2"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="basicinfo" title="Basic Info">
              <Row>
                <Col md={5} xl={4} className="view_profile mb-3 ">
                  <div className="user_info text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={preview ? preview : Avatar}
                        className="rounded-circle img-fluid profilepic"
                        alt=""
                      />
                    </div>
                    <label
                      for="uploadprofileimg"
                      className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                    >
                      Choose Picture
                    </label>
                    <input
                      type="file"
                      id="uploadprofileimg"
                      className="d-none"
                      onChange={onChange}
                    />
                  </div>
                </Col>
                <Col md={7} xl={8}>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {nameErr && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {nameErr}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailErr && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {emailErr}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Phone No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter phone no."
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          {phoneErr && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {phoneErr}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    {loader == true ? (
                      <Oval
                        height="30"
                        width="30"
                        radius="30"
                        color="#f2f5f3"
                        ariaLabel="loading"
                        wrapperStyle={{ marginLeft: 40 }}
                        wrapperClass
                      />
                    ) : (
                      <Button
                        className="btn btn-primary px-4 mt-3 min_width140"
                        onClick={(e) => {
                          onSubmit();
                        }}
                      >
                        Update
                      </Button>
                    )}
                    <ToastContainer />
                  </Form>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="changepassword" title="Change Password">
              <Row>
                <Col md={8} xl={9}>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Current Password"
                            onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                            value={password}
                          />
                          {passworderr && Submit1 ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {passworderr}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"

                            onChange={(e) => setNewpassword(e.target.value)} // set the onChange handler
                            value={Newpassword}
                          />
                        </Form.Group>
                        {Newpassworderr && Submit1 ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {Newpassworderr}
                            </div>
                          ) : null}
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Confirm New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm New Password"
                            onChange={(e) => setconfirmPass(e.target.value)} // set the onChange handler
                            value={confirmPass}
                          />
                          {confirmPasserr && Submit1 ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {confirmPasserr}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      
                    </Row>
                    {loader1 == true ? (
                      <Oval
                        height="30"
                        width="30"
                        radius="30"
                        color="#f2f5f3"
                        ariaLabel="loading"
                        wrapperStyle={{ marginLeft: 40 }}
                        wrapperClass
                      />
                    ) : (
                      <Button
                        className="btn btn-primary px-4 mt-3 min_width140"
                        onClick={(e) => {
                          OnResetPassword()
                        }}
                      >
                        Update
                      </Button>
                      
                    )}
                    
                  </Form>
                  <ToastContainer />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
